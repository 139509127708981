body {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*Disables pull-to-refresh*/
    overscroll-behavior-y: contain;
    /* Disable Text Selection */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.appLoader {
    z-index: 10;
    position: absolute;
}

.footer {
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.backdrop {
    background-color: rgba(11, 12, 12, 0.95);
    z-index: 11;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.dialog {
    z-index: 11;
    position: absolute;
    top: 35%;
    right: 10%;
    width: 80%;
    visibility: visible;
    background: white;
    box-shadow: -2rem 2rem 2rem rgba(11, 12, 12, 0.50);
}

.scrollList {
    overflow-y: scroll;
    height: 80vh;
}
